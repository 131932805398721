(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/share-coupon/assets/javascripts/share-coupon.js') >= 0) return;  svs.modules.push('/components/tipsen/share-coupon/assets/javascripts/share-coupon.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  ReactIcon
} = svs.ui;
const {
  useShareableCouponUrl
} = svs.components.tipsen.couponId;
const {
  analytics
} = svs.components;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  CouponQueryParams
} = svs.components.sport.tipsenShared;
const {
  selectCouponStatus
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  COUPON_VALID
} = svs.components.tipsen.engine.constants.couponStatus;
const {
  ShareButton,
  ShareStatus
} = svs.components.tipsen.shareCoupon;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const shareConfig = {
  title: 'Spela hos Svenska Spel S&C',
  text: "Spela hos Svenska Spel S&C"
};
const shareMessages = {
  [ShareStatus.CopiedToClipboard]: 'Länken är kopierad, dela den med dina vänner',
  [ShareStatus.CopiedToClipboardFallback]: 'Länken är kopierad, dela den med dina vänner',
  [ShareStatus.Text]: 'Kopiera länken och dela den med dina vänner',
  [ShareStatus.Shared]: ''
};
const ShareCoupon = _ref => {
  let {
    className,
    disabled
  } = _ref;
  const isWideDevice = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  const couponId = useCouponId();
  const couponStatus = useSelector(state => selectCouponStatus(state, couponId));
  const isCouponValid = couponStatus === COUPON_VALID;
  const couponUrl = useShareableCouponUrl();
  disabled = disabled || !couponUrl;
  couponUrl && couponUrl.searchParams.set(CouponQueryParams.PARAM_SHARE, isCouponValid ? 'valid' : 'invalid');
  const trackOnClick = () => {
    analytics.trackEvent({
      category: 'Nya tipsen',
      action: 'Dela din kupong',
      opt_label: couponUrl.toString()
    });
  };
  return React.createElement(ShareButton, {
    className: className,
    disabled: disabled,
    isTransparent: true,
    onShare: trackOnClick,
    shareConfig: shareConfig,
    shareMessages: shareMessages,
    shareText: couponUrl ? couponUrl.toString() : null
  }, React.createElement(ReactIcon, {
    icon: "players",
    size: isWideDevice ? '300' : '100'
  }), "Dela kupong!");
};
setGlobal('svs.components.tipsen.shareCoupon.ShareCoupon', ShareCoupon);

 })(window);